jQuery(document).ready(function($) {
	/* Mobile menu */
	$(".mobile-navigation-menu").mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	$(".mobile-menu-button").click(function() {
		if ($( ".mobile-navigation-menu" ).hasClass( "mm-opened" )) {
			$(".mobile-navigation-menu").data( "mmenu" ).close();
		}
		else {
			$(".mobile-navigation-menu").data( "mmenu" ).open();
		}
	});

	/* FitVids */
	if ($("body").innerWidth() <= 991 ) {
		$(".inside-content-mine").fitVids({ ignore: ".nofit" });
		$(".homesection").fitVids({ ignore: ".nofit" });
	}

	/* Accessible menu */
	$(".nav-dropdown:has('.container-wrapper')").addClass("has-dropdown");

	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".nav-dropdown > .navigation-link").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".nav-dropdown").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".nav-dropdown").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	/* Webflow: Interactions: Init */
	Webflow.require('ix').init([
		{slug: "dropdown-on-hover",name: "Dropdown on Hover",value: {style: {},triggers: [{type: "hover",selector: ".nav-dropdown-list",siblings: true,stepsA: [{}],stepsB: [{display: "none",transition: "opacity 300ms ease 0ms"}]}]}},
		{slug: "dropdown-on-hover-list-function",name: "Dropdown on Hover - List Function",value: {style: {},triggers: [{type: "hover",stepsA: [{display: "block"}],stepsB: [{display: "none"}]}]}},
		{slug: "search-button",name: "Search Button",value: {style: {},triggers: [{type: "click",selector: ".header-search-wrap",stepsA: [{display: "block"}],stepsB: [{display: "none"}]},{type: "click",stepsA: [{opacity: 0.7500000000000001,transition: "opacity 500ms ease 0ms"}],stepsB: [{opacity: 0.35000000000000003,transition: "opacity 500ms ease 0ms"}]}]}},
		{slug: "nav-dd-ease",name: "Nav DD Ease",value: {style: {},triggers: [{type: "hover",selector: ".nav-dropdown-list",descend: true,stepsA: [{opacity: 1,height: "auto",transition: "opacity 300ms ease 0ms, height 300ms ease 0ms"}],stepsB: [{opacity: 0,height: "0px",transition: "opacity 300ms ease 0ms, height 300ms ease 0ms"}]}]}},
		{slug: "main-nav-dd-reveal",name: "Main Nav DD Reveal",value: {style: {},triggers: [{type: "dropdown",selector: ".nav-dropdown-list",siblings: true,stepsA: [{opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "opacity 300ms ease 0ms, height 500ms ease 0ms"}],stepsB: []}]}},
		{slug: "search-dd-reveal",name: "Search DD Reveal",value: {style: {},triggers: [{type: "click",selector: ".header-search-wrap",stepsA: [{height: "auto",transition: "height 300ms ease 0ms"}],stepsB: [{height: "0px",transition: "height 300ms ease 0ms"}]}]}}
	]);
});
